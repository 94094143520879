import { restrictedmailFormFields } from '../general/emailForm';
import { Form } from '../../interfaces';
import { selectField } from '../../models';
import { formOverride } from '../../helpers';

export const sendProposalForm = (
  options: { includeTo?: boolean; includeFiles?: boolean },
  name: string,
  title: string,
  overrides: any = {}
): Form =>
  formOverride(
    {
      name,
      fieldType: 'form',
      state: {
        title,
        showErrorsBeforeSubmit: true,
      },
      fields: options.includeTo
        ? [selectField('individual', 'To', [], {}), ...restrictedmailFormFields(options.includeFiles)]
        : restrictedmailFormFields(options.includeFiles),
    },
    overrides
  );
