export type CallStatus =
  | 'requested'
  | 'queued'
  | 'initiated'
  | 'ringing'
  | 'in-progress'
  | 'completed'
  | 'busy'
  | 'failed'
  | 'no-answer'
  | 'canceled'
  | 'subscription-failed'
  | 'unknown'
  | 'pending';

export const callStatusLabels: Record<CallStatus, string> = {
  requested: 'Requested',
  queued: 'Queued',
  initiated: 'Initiated',
  ringing: 'Ringing',
  ['in-progress']: 'In progress',
  completed: 'Completed',
  busy: 'Busy',
  failed: 'Failed',
  ['no-answer']: 'No answer',
  canceled: 'Canceled',
  ['subscription-failed']: 'Unknown',
  unknown: 'Unknown',
  pending: 'Pending',
};

export const callConnectionStatusLabels: Record<CallStatus, string> = {
  requested: 'Connecting',
  queued: 'Connecting',
  initiated: 'Connecting',
  ringing: 'Connecting',
  ['in-progress']: 'Connected',
  completed: 'Disconnected',
  busy: 'Disconnected',
  failed: 'Disconnected',
  ['no-answer']: 'Disconnected',
  canceled: 'Disconnected',
  ['subscription-failed']: 'Unknown',
  unknown: 'Unknown',
  pending: 'Connecting',
};

export interface CallStatusResponse {
  id: string;
  status: CallStatus;
  timestamp: string;
}

export function isActiveCall(status: CallStatus = 'unknown') {
  return ['queued', 'initiated', 'ringing', 'in-progress', 'requested'].includes(status);
}

export function isCallConnecting(status: CallStatus = 'unknown') {
  return isActiveCall(status) && !isCallInProgress(status);
}

export function isEstablishedCall(status: CallStatus = 'unknown') {
  return ['initiated', 'ringing', 'in-progress'].includes(status);
}

export function isCallInProgress(status: CallStatus = 'unknown') {
  return ['in-progress'].includes(status);
}

export function isFinishedCall(status: CallStatus = 'unknown') {
  return ['completed', 'busy', 'failed', 'no-answer', 'canceled'].includes(status);
}
